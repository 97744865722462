import React from 'react';
import ButtonBorder from '../../../../components/ButtonBorder';

import { useHistory } from 'react-router-dom';

import Image2 from '../../../../images/photos/Image-2.png';

import Icon from '../../../../images/icons/item-description-benefits.svg'

import './style.css'
import { Link } from 'react-router-dom';

function Benefices() {
  const history = useHistory()
  return(
    <>
    <section className="beneficios">
        <img src={Image2} alt=""/>
        <div className="description-benefits">
          <h1>TUDO O QUE VOCÊ PRECISA <br/> E MAIS UM MONTE DE BENEFÍCIOS</h1>
          <ul>
            <span>
              <h3>Mais Saúde</h3>
              <li>
                <p>Desconto em mais de 20 especialidades médicas particulares. Tudo isso com valor acessível.</p>
              </li>
            </span>
            <span>
              <h3>Mais Saúde Plus</h3>
              <li>
                <p>Consultas ilimitadas em mais de 20 especialidades médicas. Sem valor adicional.</p>
              </li>
            </span>
            <span>
              <h3>WinCupom</h3>
              <li>
                <p>Descontos em mais de 25 mil estabelecimento em todo Brail, com cashback em dinheiro.</p>
              </li>
            </span>
            <span>
              <h3>Auxilio Pet</h3>
              <li>
                <p>Consultas emergenciais, atendimento ambulatorial, cirurgias em casos de acidentes. Tudo para a segurança e conforto para o seu pet.</p>
              </li>
            </span>
            <span>
              <h3>Telemedicina</h3>
              <li>
                <p>Atendimento remoto feito por médicos altamente qualificados.</p>
              </li>
            </span>
            <ButtonBorder href="https://links.icamais.com/?fbclid=PAAaZXO3cdLVaUrmrIQ4lSZRDqgphDhUv7Xrn6fX_r6I1qZ3MFuNZhTyhsvV0" colorBorder="#1EF97C" text="QUERO ABRIR MINHA CONTA" />
          </ul>
          <h4 className='description--benefices-funcionaries-h4'>*Saiba mais através dos nossos consultores.</h4>
        </div>
      </section>
    <section className="beneficios-mobile">
      <h1>TUDO O QUE VOCÊ PRECISA E MAIS UM MONTE <green>BENEFÍCIOS</green></h1>
      <div className="description-beneficios-mobile">
        <ul>
          <li>
            <h2>Mais Saúde</h2>
            <div className="icon-description-mobile">
            <img src={Icon} alt="" />
            <p>Desconto em mais de 20 especialidades médicas particulares. Tudo isso com valor acessível.</p>
            </div>
          </li>
          <li>
            <h2>Mais Saúde Plus</h2>
            <div className="icon-description-mobile">
            <img src={Icon} alt="" />
            <p>Consultas ilimitadas em mais de 20 especialidades médicas. Sem valor adicional.</p>
            </div>
          </li>
          <li>
            <h2>WinCupom</h2>
            <div className="icon-description-mobile">
            <img src={Icon} alt="" />
            <p>Descontos em mais de 25 mil estabelecimento em todo Brail, com cashback em dinheiro.</p>
            </div>
          </li>
          <li>
            <h2>Auxilio Pet</h2>
            <div className="icon-description-mobile">
            <img src={Icon} alt="" />
            <p>Consultas emergenciais, atendimento ambulatorial, cirurgias em casos de acidentes. Tudo para a segurança e conforto para o seu pet.</p>
            </div>
          </li>
          <li>
            <h2>Telemedicina</h2>
            <div className="icon-description-mobile">
            <img src={Icon} alt="" />
            <p>Atendimento remoto feito por médicos altamente qualificados.</p>
            </div>
          </li>
        </ul>
        <h4 className='description-benefices-funcionaries-h4' style={{marginTop: '10px', marginLeft: '10px'}}>*Saiba mais através dos nossos consultores.</h4>
        <div onClick={() => {
          window.open("https://links.icamais.com/?fbclid=PAAaZXO3cdLVaUrmrIQ4lSZRDqgphDhUv7Xrn6fX_r6I1qZ3MFuNZhTyhsvV0");
        }} className="description-beneficios-mobile-btn">QUERO ABRIR MINHA CONTA</div>
      </div>
    </section>
    </>
  );
}

export default Benefices;
