import React from 'react';
import CardItem from '../../components/CardItem';

import ImageComputer from '../../../../images/photos/Image-computer.png';
import Computer from '../../../../images/photos/Computer.png';

import IconList from '../../../../images/icons/item-description-benefits.svg'
import FogueteEsquerda from '../../../../images/icons/Foguete-Esquerda.svg'
import FogueteDireita from '../../../../images/icons/Foguete-Direita.svg'


import './style.css';

function Ecommerce() {
  return(
    <>
    <section className="ecommerce">
      <div className="container-ecommerce">
        <img className="image-computer" src={ImageComputer} alt=""/>
        <div className="description-ecommerce">
          <h1>NOSSO E-COMMERCE É PERFEITO PARA VOCÊ QUE PRECISA DE AJUDA</h1>
          <ul>
            <CardItem title="SUPER ICA LINK" description="O jeito mais fácil de vender na internet sem precisar de um site. É só gerar o link e vender" />
            <CardItem title="TECNOLOGIA" description="Plataforma com centenas de recursos, de fácil integração e adaptável para qualquer dispositivo" />
            <CardItem title="BLINDAGEM CONTRA FRAUDES" description="Sistema de inteligência artificial com machine learning e a melhor ferramenta antifraude do mercado" />
            <CardItem title="DATA INTELIGENCE" description="Dados do mercado para você tomar as melhores decisões para o seu negócio" />
            <CardItem title="MAIS CONVERSÃO" description="Análise minuciosa das transações para trabalhar retentativas e recorrência de vendas" />
            <CardItem title="LOJA VIRTUAL" description="Integre a sua Loja Virtual a uma página de pagamentos pronta da Cielo" />
            <CardItem title="SEU NEGÓCIO DIGITAL SEMPRE DISPONÍVEL" description="Capacidade de suportar 8x o volume de transações do mercado brasileiro" />
            <CardItem title="SUPORTE AMPLO" description="Tem algum problema na plataforma? Uma equipe de especialistas estará pronta pra ajudar" />
          </ul>
        </div>
      </div>
    </section>
    <section className="ecommerce-mobile">
      <div className="container-mobile">
        <div className="image">
          <img src={Computer} alt="" />
        </div>
        <div className="description-ecommerce-mobile">
          <h1>NOSSO <green>E-COMMERCE</green> É PERFEITO PARA VOCÊ QUE PRECISA DE AJUDA</h1>  
          <ul>
            <li>
              <div className="list-item-mobile">
                <div className="list-header">
                <img src={IconList} alt="" />
                  <h2>SUPER ICA LINK</h2>
                </div>
                <p>O jeito mais fácil de vender na internet sem precisar de um site. É só gerar o link e vender</p>
              </div>
              <img src={FogueteDireita} alt="" />
            </li>

            <li className="list-item">
              <div className="espaço"></div>
              <div className="list-item-mobile">
                <div className="list-header">
                <h2>TECNOLOGIA</h2>
                <img style={{marginLeft: '15px'}} src={IconList} alt="" />
                </div>
                <p style={{textAlign:'end'}}>Plataforma com centenas de recursos, de fácil integração e adaptável para qualquer dispositivo</p>
              </div>
            </li>

            <li className="list-item">
              <div className="list-item-mobile">
                <div className="list-header">
                <img src={IconList} alt="" />
                <h2>BLINDAGEM CONTRA FRAUDES</h2>
                </div>
                <p>Sistema de inteligência artificial com machine learning e a melhor ferramenta antifraude do mercado</p>
              </div>
              <div className="espaço"></div>
            </li>

            <li>
              <div className="espaço">
              <img src={FogueteEsquerda} alt="" />
              </div>
              <div className="list-item-mobile">
                <div className="list-header">
                  <h2>DATA INTELIGENCE</h2>
                <img src={IconList} alt="" />
                </div>
                <p style={{textAlign: 'end'}}>Dados do mercado para você tomar as melhores decisões para o seu negócio</p>
              </div>
            </li>

            <li>
              <div className="list-item-mobile">
                <div className="list-header">
                <img src={IconList} alt="" />
                  <h2>MAIS CONVERSÃO</h2>
                </div>
                <p >Análise minuciosa das transações para trabalhar retentativas e recorrência de vendas</p>
              </div>
            </li>

            <li>
              <div className="espaço">
              </div>
              <div className="list-item-mobile">
                <div className="list-header">
                  <h2>SUPORTE AMPLO</h2>
                <img src={IconList} alt="" />
                </div>
                <p style={{textAlign: 'end'}}>Tem algum problema na plataforma? Uma equipe de especialistas estará pronta pra ajudar</p>
              </div>
            </li>

            <li>
              <div className="list-item-mobile">
                <div className="list-header">
                <img src={IconList} alt="" />
                  <h2>SEU NEGÓCIO DIGITAL SEMPRE DISPONÍVEL</h2>
                </div>
                <p>Capacidade de suportar 8x o volume de transações do mercado brasileiro</p>
              </div>
              <img src={FogueteDireita} alt="" />
            </li>

            <li>
              <div className="espaço">
              </div>
              <div className="list-item-mobile">
                <div className="list-header">
                  <h2>LOJA VIRTUAL</h2>
                <img style={{marginLeft: '15px'}} src={IconList} alt="" />
                </div>
                <p style={{textAlign: 'end'}}>Integre a sua Loja Virtual a uma página de pagamentos pronta da Cielo</p>
              </div>
            </li>
            
          </ul>
        </div>
      </div>
    </section>
    </>
  );
}

export default Ecommerce;
