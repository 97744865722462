import React from 'react';

import './style.css';

function InputAnimated(props) {
  return(
    <div className="input-animated">
      <div className="input-data">
        <input name={props.name} maxLength={props.maxChar} onChange={props.onChange} value={props.value} type={props.type} required/>
        <label htmlFor="">{props.children}</label>
        <div className="underline"></div>
      </div>
    </div>
  );
}

export default InputAnimated;
