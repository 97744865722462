import { Switch, Route, BrowserRouter, HashRouter } from 'react-router-dom';

import Error404 from './pages/Error404';
import Home from './pages/Home/index';
import IcaCard from './pages/IcaCard';
import IcaEmpresa from './pages/IcaEmpresa';
import IndiqueGanhe from './pages/IndiqueGanhe';
import Maquininha from './pages/Maquininha';
import PreCadastro from './pages/PreCadastro';
import ProdutosIca from './pages/ProdutosIca';
import ProtecaoPix from './pages/ProtecaoPix';
import Politic from './pages/Politic';

function Routes() {
  return (
    <BrowserRouter >
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/empresa" component={IcaEmpresa} />
        <Route path="/maquininha" component={Maquininha} />
        <Route path="/abraconta" component={PreCadastro} />
        <Route path="/produtosica" component={ProdutosIca} />
        <Route path="/protecaopix" component={ProtecaoPix} />
        <Route path="/indiqueganhe" component={IndiqueGanhe} />
        <Route path="/icacard" component={IcaCard} />
        <Route path="/privacyInvest" component={Politic} />
        <Route exact path="/*" component={Error404} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes;