import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';

import ImagePreCadastro from '../../images/photos/Image-PreCadastro.png';
import QrCodeAppStore from '../../images/qr-code-app-store.svg'
import QrCodePlayStore from '../../images/qr-code-play-store.svg';


import AppStore from '../../images/icons/Download-appstore.svg';
import Play from '../../images/google-play.png';

import './style.css'

function PreCadastro() {

  return(
    <>
      <Header />
      <section className="pre-cadastro">
        <img className="pre-cadastro-image" src={ImagePreCadastro} alt=""/>
        <div className="form-pre-cadastro">
          <h1>ABRA <green>SUA CONTA</green> ICA BANK</h1>
          <p>Baixe nosso app e faça parte do time, é grátis!</p>
          <div className="download-qrCode">
            <div>
              <img src={QrCodeAppStore} alt="" />
              <a href="https://apps.apple.com/br/app/icabank/id1534349317">
                <img src={AppStore} alt="" />
              </a>
            </div>
            <div>
              <img src={QrCodePlayStore} alt="" />
              <a href="https://play.google.com/store/apps/details?id=com.icawhitelabel&hl=pt_BR&gl=US">
                <img src={Play} alt="" />
              </a>
            </div>
          </div>
          <div className="download-qrCode-mobile">
              <a href="https://apps.apple.com/br/app/icabank/id1534349317">
                <img src={AppStore} alt="" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.icawhitelabel&hl=pt_BR&gl=US">
                <img src={Play} alt="" />
              </a>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default PreCadastro;
