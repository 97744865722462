import React from 'react';

import ImageBeneficesFuncionares from '../../../../images/photos/Image-benefices-funcionaries.png'
import IconList from '../../../../images/icons/item-description-benefits.svg'

import './style.css';

function BeneficesFuncionaries() {
  return(
    <>
    <section className="benefices-funcionaries">
      <div className="description-benefices-funcionaries">
        <h1>BENEFÍCIOS PRA FUNCIONÁRIOS QUE SÃO ICA BANK</h1>
        <p>Taxas e cashback, exclusivo para clientes ICA Bank</p>
        <ul>
          <li> <img src={IconList} alt="" /> <p>Mais Saúde - Desconto em mais de 20 especialidades médicas particulares.</p></li>
          <li> <img src={IconList} alt="" /> <p>Mais Saúde Plus - Consultas ilimitadas em mais de 20 especialidades médicas. Sem valor adicional.</p></li>
          <li> <img src={IconList} alt="" /> <p>WinCupom - Descontos em mais de 25 mil estabelecimento em todo Brail, com cashback em dinheiro.</p></li>
          <li> <img src={IconList} alt="" /> <p>Auxilio Pet - Consultas emergenciais, atendimento ambulatorial, cirurgias em casos de acidentes. Tudo para a segurança e conforto para o seu pet.</p></li>
          <li> <img src={IconList} alt="" /> <p>Telemedicina - Atendimento remoto feito por médicos altamente qualificados.</p></li>
        </ul>
        <h4 className='description-benefices-funcionaries-h4'>*Saiba mais através dos nossos consultores.</h4>
      </div>
      <img className="image-benefices-funcionaries" src={ImageBeneficesFuncionares} alt="Benefices funcionaries"/>
    </section>
    <section className="benefices-funcionaries-mobile">
      <div className="image-benefices-funcionaries-mobile">
      <img src={ImageBeneficesFuncionares} alt="Benefices funcionaries"/>
      </div>
      <div className="description-benefices-funcionaries-mobile">
        <h1>BENEFÍCIOS PRA <green>FUNCIONÁRIOS</green> QUE SÃO ICA BANK</h1>
        <ul>
          <li> <img src={IconList} alt="" /> <p>Mais Saúde - Desconto em mais de 20 especialidades médicas particulares. Tudo isso com valor acessível.</p></li>
          <li> <img src={IconList} alt="" /> <p>Mais Saúde Plus - Consultas ilimitadas em mais de 20 especialidades médicas. Sem valor adicional.</p></li>
          <li> <img src={IconList} alt="" /> <p>WinCupom - Descontos em mais de 25 mil estabelecimento em todo Brail, com cashback em dinheiro.</p></li>
          <li> <img src={IconList} alt="" /> <p>Auxilio Pet - Consultas emergenciais, atendimento ambulatorial, cirurgias em casos de acidentes. Tudo para a segurança e conforto para o seu pet.</p></li>
          <li> <img src={IconList} alt="" /> <p>Telemedicina - Atendimento remoto feito por médicos altamente qualificados.</p></li>
        </ul>
        <h4 className='description-benefices-funcionaries-h4'>*Saiba mais através dos nossos consultores.</h4>
      </div>
    </section>
    </>
    );
}

export default BeneficesFuncionaries;
