import React from 'react';

import ItemDescription from '../../../../images/icons/item-description-benefits-withe.svg';

import './style.css'

function CardItem(props) {
  return(
    <li>
      <div>
        <img src={ItemDescription} alt={props.title}/>
        <h2>{props.title}</h2>
      </div>
      <p>{props.description}</p>
    </li>
  );
}

export default CardItem;
