import React, { useState } from 'react';
import Iframe from 'react-iframe';

const PDFViewer = ({ pdfPath }) => {
  return (
    <div style={{height: '100vh'}}>
      <Iframe
        url={pdfPath}
        width="100%"
        height="100%"
        id="pdf-iframe"
        className="pdf-iframe"
        display="initial"
        position="relative"
      />
    </div>
  );
};

export default PDFViewer;