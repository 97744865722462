import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import CircleImage from '../../images/photos/Circle.png';
import pix from '../../images/photos/pix.svg';
import LogoPix from '../../images/photos/logo-pix.svg'
import IconList from '../../images/icons/item-description-benefits.svg';

import Icon from '../../images/icons/item-description-benefits.svg'

import './style.css';

function ProtecaoPix() {
  return(
    <>
      <Header />
      <section className="protection-pix">
        <div className="containt-protection-pix">
          <div className="card-1">
            <h1>ANTIFRAUDE PIX</h1>
            <p>Nós temos uma ferramenta que previe golpes em transações feitas pelo Pix e garantima segurança de nossos usuários.</p>
          </div>
          <div className="circle-containt">
            <img src={CircleImage} alt=""/>
            <div className="cards-circle-containt">
              <div className="card-single-circle">
                <h2>Pagamento</h2>
                <p>Proteja-se no momento dos pagamentos e recebimentos com decisões real-time</p>
              </div>
              <div className="cards-circle">
                <div>
                  <h2>Dict</h2>
                  <p>Proteção no cadastro de chaves na DICT para verificá-las e evitar fraudes</p>
                </div>
                <div>
                  <h2>Alertas</h2>
                  <p>Ferramenta completa de Machine Learning para geração de alertas de fraude e compliance</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="list-modulos">
          <div className="modulos-description">
            <div>
              <img src={IconList} alt=""/>
              <h2>MÓDULO DE PAGAMENTO</h2>
            </div>
            <p>Consulta de pagamentos, recebimentos e score</p>
            <p>Dashboard de análise manual</p>
            <p>Dashboard de consulta de transações</p>
            <p>Consultas em tempo real</p>
          </div>
          <div className="modulos-description">
            <div>
              <img src={IconList} alt=""/>
              <h2>MÓDULO DICT</h2>
            </div>
            <p>Validação da chave com base de dados de bureau e telefone</p>
            <p>Motor de regras personalizado</p>
            <p>Dashboard de consulta do histórico de cadastro</p>
            <p>Validação da operação via link por email ou sms</p>
          </div>
          <div className="modulos-description">
            <div>
              <img src={IconList} alt=""/>
              <h2>MÓDULO DE ALERTAS</h2>
            </div>
            <p>4 níveis de criticidade</p>
            <p>Dashboard de consulta do história de alertas</p>
            <p>Alertas de compliance incluindo batimento de transações PEPs e listas de sanções</p>
            <p>Registros dos alertas em pdf para futuras auditorias</p>
          </div>
        </div>
      </section>

      <section className="protection-pix-mobile">
        <h1>ANTIFRAUDE <green>PIX</green></h1>
        <p className="sub-title">Nós temos uma ferramenta que prevê golpes em transações feitas pelo Pix e garante a segurança de nossos usuários.</p>
        <img className="LogoPix" src={LogoPix} alt="" />
        <img src={pix} alt="" />
        <ul>
          <li>
            <div>
              <img src={Icon} alt="" />
              <h2>MÓDULO DE PAGAMENTO</h2>
            </div>
            <p>Consulta de pagamentos, recebimentos e score <br />
            Dashboard de análise manual <br />
            Dashboard de consulta de transações <br />
            Consultas em tempo real</p>
          </li>
          <li>
            <div>
              <img src={Icon} alt="" />
              <h2>MÓDULO DICT</h2>
            </div>
            <p>
              Validação da chave com base de dados de bureau e telefone <br />
              Motor de regras personalizado <br />
              Dashboard de consulta do histórico de cadastro <br />
              Validação da operação via link por email ou sms</p>
          </li>
          <li>
            <div>
              <img src={Icon} alt="" />
              <h2>MÓDULO DE ALERTAS</h2>
            </div>
            <p>
              4 níveis de criticidade <br />
              Dashboard de consulta do história de alertas <br />
              Alertas de compliance incluindo batimento de transações PEPs e listas de sanções <br />
              Registros dos alertas em pdf para futuras auditorias
            </p>
          </li>
        </ul>
      </section>
      <Footer />
    </>
  );
}

export default ProtecaoPix;
