import React from 'react';
import { Link } from 'react-router-dom';

import ImageCard from '../../../../images/photos/Image-card.png';
import ImageCardMobile from '../../../../images/photos/Image-card-mobile.svg';

import './style.css';

function Card() {
  return(
    <>
    <section className="cartao">
        <img className="image-card" src={ImageCard} alt=""/>
        <div className="desc-card">
        <h1>DaBahia direto <br/>para suas mãos!</h1>
        <p className="desc-card-min" >O ICA CARD é uma administradora de cartões de antecipação salarial e benefícios. A empresa surgiu na Bahia com o intuito d e administrar os cartões contratados pelos institutos, empresas privadas, sindicatos e para as associações, tendo como principal diferencial o foco na inovação.</p>
        <p>Nossas equipes são preparadas para atender a todas as necessidades de nossos proporcionando qualidade, segurança, clientes, confiabilidade, conforto e alto padrão de atendimento, totalmente em um modelo de negócios inovador.</p>
        <div className="button">
          <a href="https://wa.me/message/G74XTO3CBWIRA1">QUERO PEDIR UM ICA CARD</a>
        </div>
        </div>
      </section>
      <section className="cartao-mobile">
        <img src={ImageCardMobile} alt="" />
      </section>
    </>
  );
}

export default Card;
