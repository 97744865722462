import React, {useState} from 'react';
import {telMask} from '../../cpfMask';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import InputAnimated from '../../components/InputAnimated';

import api from '../../utils/api';

import MaquininhaSua from '../../images/photos/Maquininha-peça-a-sua.png';
import { useHistory } from 'react-router-dom';

import './style.css';

function Maquininha() {
  const history = useHistory()
  const [tel, setTel] = useState('')
  const [data, setData] =useState({
    name: "",
	  email: "",
	  telephone: "",
	  company: "",
	  city: ""
  })

  function teleMaskChange({target}) {
    setTel(telMask(target.value))
    setData({
      ...data,
      telephone: telMask(target.value)
    })
  }

  async function sendEmail() {
    if(data.name === '' || data.email === '' || data.telephone === '' || data.company === '' || data.city === '') {
      alert('insira os dados')
    }else {
      try {
        await api.post('request-machine', data)
        
        setTimeout(() => {
          alert("email enviado com sucesso!")
          history.push('/')
        }, 500);
      } catch (error) {
        console.log(error)
      }
    }
    
  }

  function handleCahnge({target}) {
    const {name, value} = target

    setData({
      ...data,
      [name]: value,
      telephone: tel
    })

  }

  return(
    <>
      <Header />
      <section className="sua-maquininha">
        <div className="sua-maquininha-description">
          <h1>ENTRE EM CONTATO PARA PEDIR SUA MAQUININHA</h1>
          <InputAnimated name="name" onChange={handleCahnge} type="text" id="name" >Nome Completo</InputAnimated>
          <InputAnimated name="company" onChange={handleCahnge} type="text" id="empresa" >Nome da empresa</InputAnimated>
          <InputAnimated name="city" onChange={handleCahnge} type="text" id="city" >Cidade</InputAnimated>
          <InputAnimated maxChar="15" value={tel} onChange={teleMaskChange} >Telefone</InputAnimated>
          <InputAnimated name="email" onChange={handleCahnge} type="text" id="email" >Email</InputAnimated>
          <input onClick={sendEmail} type="button" value="ENVIAR"/>
        </div>
        <img src={MaquininhaSua} alt=""/>
      </section>

      <section className="sua-maquininha-mobile">
        <div className="sua-maquininha-description-mobile">
          <h1>ENTRE EM CONTATO PARA PEDIR SUA <green>MAQUININHA</green></h1>
        <img src={MaquininhaSua} alt=""/>
          <div className="container-mobile-forms">
          <InputAnimated name="name" onChange={handleCahnge} type="text" id="name" >Nome Completo</InputAnimated>
          <InputAnimated name="company" onChange={handleCahnge} type="text" id="empresa" >Nome da empresa</InputAnimated>
          <InputAnimated name="city" onChange={handleCahnge} type="text" id="city" >Cidade</InputAnimated>
          <InputAnimated maxChar="15" value={tel} onChange={teleMaskChange} >Telefone</InputAnimated>
          <InputAnimated name="email" onChange={handleCahnge} type="text" id="email" >Email</InputAnimated>
          <input onClick={sendEmail} type="button" value="ENVIAR"/>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Maquininha;
