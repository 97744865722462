import React from 'react';
import { Link } from 'react-router-dom';

import MaquininhaEmpresa from '../../../../images/photos/Maquininha-empresas.png';
import MaquininhaEmpresaMobile from '../../../../images/photos/Image-maquininha-empresa.svg';
import MaquininhaEmpresaMobile2 from '../../../../images/photos/Image-maquininha-mobile2.png';
import Maquininha2 from '../../../../images/photos/Image-maquininha.png';

import './style.css';

function Maquininha() {
  return(
    <>
    <section className="maquininha-empresas">
      <img src={MaquininhaEmpresa} alt=""/>
      <div className="maquininha-text">
        <h2>NÓS EVOLUÍMOS O BANCO E TAMBÉM A MAQUINIHA DE VENDAS</h2>
        <p>A única maquininha que dá benefícios para quem vende e ainda é integrada com plataforma de e-commerce.<br /><br />Com o antecipamento de recebíveis D+1 ficou mais fácil saber quanto você tem em caixa e fechar as contas do mês antes do último dia últil.<br /><br />Muita bateria, fácil de carregar e resistente, a maquininha do ICA Bank é ideal para você que já teve algum problema e o banco demorou a resolver.<br /><br />Peça já a sua e comece a vender já.</p>
      <Link to="/maquininha">QUERO PEDIR UMA MAQUININHA</Link>
      </div>
    </section>
    
    <section className="maquininha-empresas-mobile">
      <div className="maquininha-desc">
      <img src={MaquininhaEmpresaMobile} alt="" />
      </div>
      <div className="description-maquininha">
      <img width="35%" src={Maquininha2} alt="" />
      <p>
      A única maquininha que dá benefícios para quem vende e ainda é integrada com plataforma de e-commerce.
      <br /><br />
      Com o antecipamento de recebíveis D+1 ficou mais fácil saber quanto você tem em caixa e fechar as contas do mês antes do último dia últil.
      </p>
      </div>
      <div className="bar"></div>
      <Link to="/maquininha">QUERO PEDIR UMA MAQUININHA</Link>
    </section>
    </>
  );
}

export default Maquininha;
