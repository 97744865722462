import React from 'react';

import IntroHomeEmpresas from '../../../../images/photos/Intro-home-empresas.png';
import IntroHomeEmpresasMobile from '../../../../images/photos/Intro-home-empresas-mobile.png';

import './style.css';
 
function IntroHome() {
  return(
    <>
    <section className="intro-home-page">
      <h1>O BANCO QUE TRAZ CASHBACK<br />DA FOLHA DE PAGAMENTO</h1>
      <div className="intro-home-page-content">
        <img src={IntroHomeEmpresas} alt=""/>
        <div>
          <h2>BENEFÍCIOS PARA SUA EMPRESA E TAMBÉM PARA TODA SUA EQUIPE</h2>
          <p>Nós sabemos como bancos podem ser burocráticos e só estarem disponíveis na hora de fazer cobranças, por isso temos o produto ideal para você empreendedor.<br/><br/> Com o ICA Bank você tem muitas vantagens integradas direto para seu negócio e ainda a facilidade de gerir tudo pelo app. E ainda temos uma maquininha que facilita muito com antecipação de recebíveis.<br/><br/> É tanto benefício que você nem vai lembrar como era seu antigo banco.</p>
        </div>
      </div>
    </section>

    <section className="intro-home-page-mobile">
      <h1>O BANCO QUE TRAZ <green>CASHBACK</green> DA FOLHA DE PAGAMENTO</h1>
      <div className="intro-home-page-mobile-content">
        <img src={IntroHomeEmpresasMobile} alt=""/>
        <div>
          <h2>BENEFÍCIOS PARA SUA <green>EMPRESA</green> E TAMBÉM PARA TODA SUA <green>EQUIPE</green></h2>
          <p>Com o ICA Bank você tem muitas vantagens integradas direto para seu negócio e ainda a facilidade de gerir tudo pelo app. E ainda temos uma maquininha que facilita muito com antecipação de recebíveis.</p>
        </div>
      </div>
    </section>
    </>
  );
}

export default IntroHome;
