import React from 'react';

import Retangles from '../../../../images/icons/retangles.svg';

import IcaCred from '../../../../images/icons/Ica-Cred.svg';
import IcaInvest from '../../../../images/icons/Ica-Invest.svg';
import IcaCard from '../../../../images/icons/Ica-Card.svg';
import IcaPay from '../../../../images/icons/Ica-Pay.svg';

import ButtonBorder from '../../../../components/ButtonBorder';

import './style.css';

function ButtonRedirect(props) {
  const {text, colorBorder, link, width, height, size, href} = props;
  return(
    <a className="buttonB" style={{
      border: `3px solid ${colorBorder}`,
      width: width,
      height: height,
      fontSize: size
    }} href={href} >{text}</a>
  );
}

function Products() {
  return(
    <>
      <section className="products">
      <img className="retangles" src={Retangles} alt=""/>
      <div>
        <h1>CONHEÇA NOSSOS OUTROS PRODUTOS, ELES PODEM AJUDAR VOCÊ HOJE</h1>
        <div className="product-items">
          <div className="product-item">
            <img src={IcaCred} alt=""/>
            <p>Aconteceu algum aperto e precisa de dinheiro emprestado? Nós temos a solução ideal para você pessoa física</p>
            <ButtonRedirect width="286px" height="58px"  href="https://wa.me/message/G74XTO3CBWIRA1" colorBorder="#540D6E" text="QUERO SAIR DO APERTO" />
          </div>
          <div className="product-item">
            <img src={IcaInvest} alt=""/>
            <p>Invista em P2P Lending com o Ica Bank! Diversifique os seus Investimentos e aumente os seus ganhos investindo em Micro e Pequenas Empresas através de compras de recebíveis dos comércios de todo o Brasil. Os investimentos aqui no ICA BANK movimentam as economias municipais de uma forma prática, segura e rentável. Quer investir? Seu ICA INVEST!</p>
            <ButtonRedirect width="286px" height="58px"  href="https://wa.me/message/G74XTO3CBWIRA1" colorBorder="#F4A500" text="QUERO INVESTIR AGORA" />
          </div>
          <div className="product-item">
            <img src={IcaCard} alt=""/>
            <p>Nosso cartão de antecipação salarial local. Tem seu foco em desenvolvimento econômico local, gerando aumento de arrecadação de impostos para o município. Além disso, temos a opção de estender para empresas privadas com autorização de desconto em folha sem juros, aumentando o poder de compra do funcionário.</p>
            <ButtonBorder width="286px" height="58px" href="https://wa.me/message/G74XTO3CBWIRA1" colorBorder="#27A058" text="QUERO MEU CARTÃO" />
          </div>
          <div className="product-item">
            <img src={IcaPay} alt=""/>
            <p>Uma plataforma para comerciantes e clientes realizarem suas transações de maneira simples e segura. Sem contar com o E-Commerce de centenas de parceiros associados para você poder realizar suas compras e vendas com facilidade e conforto e ainda receber cashback por isso!</p>
            <ButtonRedirect width="286px" height="58px" href="https://wa.me/message/G74XTO3CBWIRA1" colorBorder="#009FFD" text="QUERO VANTAGENS PRA VENDER" />
          </div>
        </div>
      </div>
    </section>
    <section className="products-mobile">
      <h1>CONHEÇA NOSSOS OUTROS PRODUTOS</h1>
      <ul>
        <li>
          <img src={IcaCred} alt="" />
          <div>
            <p>Aconteceu algum aperto e precisa de dinheiro emprestado? Nós temos a solução ideal para você pessoa física</p>
            <ButtonBorder width="100%" height="50px" link="/" colorBorder="#540D6E" text="QUERO SAIR DO APERTO" />
          </div>
        </li>
        <li>
          <img src={IcaInvest} alt="" />
          <div>
            <p>O jeito inteligente de se investir sem sair do nosso ecossistema.</p>
            <ButtonBorder width="100%" height="50px" link="/" colorBorder="#F4A500" text="QUERO INVESTIR AGORA" />
          </div>
        </li>
        <li>
          <img src={IcaCard} alt="" />
          <div>
            <p>Nosso cartão de antecipação salarial local. Tem seu foco em desenvolvimento econômico local, gerando aumento de arrecadação de impostos para o município. Além disso, temos a opção de estender para empresas privadas com autorização de desconto em folha sem juros, aumentando o poder de compra do funcionário.</p>
            <ButtonBorder width="100%" height="50px" link="/icacard" colorBorder="#27A058" text="QUERO MEU CARTÃO" />
          </div>
        </li>
        <li>
          <img src={IcaPay} alt="" />
          <div>
            <p>Uma plataforma para comerciantes e clientes realizarem suas transações de maneira simples e segura. Sem contar com o E-Commerce de centenas de parceiros associados para você poder realizar suas compras e vendas com facilidade e conforto e ainda receber cashback por isso!</p>
            <ButtonBorder width="100%" height="50px" link="/" colorBorder="#009FFD" text="QUERO VANTAGENS PRA VENDER" />
          </div>
        </li>
      </ul>
    </section>
    </>
  );
}

export default Products;
