import React from 'react';


import ImageCel from '../../../../images/photos/Imagem-principal.png';
import ImageCelMobile from '../../../../images/photos/Imagem-principal-mobile.png';

import './style.css';

function IntroHome() {
  return(
    <>
    <section className="intro-home">
        <h1 className="title">Faça parte da evolução,<br/> Ica bank o Banco de benefícios que cuida de você!</h1>
        <div className="image-cell">
          <img width="600" src={ImageCel} alt=""/>
        </div>
        <div className="descriptions-intro"> 
          <div className="colum-items">
            <div className="item">
              <h2>ICA BANK</h2>
              <p>O banco digital voltado para benefícios como: Winculpon oferecendo os melhores descontos e cash back, telemedicina te atendendo em qualquer lugar do mundo, pagamento de folha e cash back para contas de Pessoa Jurídica e proporcionando muito mais.</p>
            </div>
            <div className="item">
              <h2>ICA CARD</h2>
              <p>Nosso cartão de antecipação salarial local. Tem seu foco em desenvolvimento econômico local, gerando aumento de arrecadação de impostos para o município. Além disso, temos a opção de estender para empresas privadas com autorização de desconto em folha sem juros, aumentando o poder de compra do funcionário.</p>
            </div>
          </div>    
          <div className="colum-items">
            <div className="item">
              <h2>ICA INVEST</h2>
              <p>Invista em P2P Lending com o Ica Bank! Diversifique os seus Investimentos e aumente os seus ganhos investindo em Micro e Pequenas Empresas através de compras de recebíveis dos comércios de todo o Brasil. Os investimentos aqui no ICA BANK movimentam as economias municipais de uma forma prática, segura e rentável. Quer investir? Seu ICA INVEST!</p>
            </div>
            <div className="item">
              <h2>ICA PAY</h2>
              <p>Tudo que você  precisa para emitir e controlar as cobranças do seu negócio, reduza a inadimplência da sua empresa e lucre mais!</p>
            </div>
          </div>
        </div>
      </section>

      <section className="intro-home-mobile">
        <h1 className="title-mobile">Faça parte da evolução,</h1>
        <div className="image-cell-mobile">
          <img src={ImageCelMobile} alt=""/>
        </div>       
        <h1 className="title-mobile">Ica bank o Banco de benefícios que cuida de você!</h1>

        <div className="descriptions-intro-mobile"> 
          <div className="description-mobile-1">
            <h1>ICA BANK</h1>
            <p>O banco digital voltado para benefícios como: Winculpon oferecendo os melhores descontos e cash back, telemedicina te atendendo em qualquer lugar do mundo, pagamento de folha e cash back para contas de Pessoa Jurídica e proporcionando muito mais.</p>
          </div>
          <div className="description-mobile-2">
            <h1>ICA CARD</h1>
            <p>Nosso cartão de antecipação salarial local. Tem seu foco em desenvolvimento econômico local, gerando aumento de arrecadação de impostos para o município. Além disso, temos a opção de estender para empresas privadas com autorização de desconto em folha sem juros, aumentando o poder de compra do funcionário.</p>
          </div>
          <div className="description-mobile-1">
            <h1>ICA INVEST</h1>
            <p>Invista em P2P Lending com o Ica Bank! Diversifique os seus Investimentos e aumente os seus ganhos investindo em Micro e Pequenas Empresas através de compras de recebíveis dos comércios de todo o Brasil. Os investimentos aqui no ICA BANK movimentam as economias municipais de uma forma prática, segura e rentável. Quer investir? Seu ICA INVEST!</p>
          </div>
          <div className="description-mobile-2">
            <h1>ICA PAY</h1>
            <p>Tudo que você  precisa para emitir e controlar as cobranças do seu negócio, reduza a inadimplência da sua empresa e lucre mais!</p>
          </div>
        </div>
      </section>
      </>
  );
}

export default IntroHome;
