import React from 'react';

import './styles.css';

import Header from './Components/Header'
import Footer from '../../components/Footer'

import IconHeart from '../../images/icons/Icon-ica-card-heart.svg'
import IconRose from '../../images/icons/Icon-ica-card-rose.svg'
import IconAcident from '../../images/icons/Icon-ica-card-acident.svg'
import IconAcident2 from '../../images/icons/Icon-ica-card-acident2.svg'
import IconHospital from '../../images/icons/Icon-ica-card-hospital.svg'
import IconDental from '../../images/icons/Icon-ica-card-dental.svg'
import IcaImage from '../../images/photos/Ica-card-image.png';

import Image1 from '../../images/photos/partners/image1.svg';
import Image2 from '../../images/photos/partners/image2.svg';
import Image3 from '../../images/photos/partners/image3.svg';
import Image4 from '../../images/photos/partners/image4.svg';
import Image5 from '../../images/photos/partners/image5.svg';
import Image6 from '../../images/photos/partners/image6.svg';
import Image7 from '../../images/photos/partners/image7.svg';
import Image8 from '../../images/photos/partners/image8.svg';
import Image9 from '../../images/photos/partners/image9.svg';



function IcaCard() {
  return (
    <>
      <Header />
      <section className="ica-card">
        <div className="ica-card-title">
          <h1>
            O CARTÃO QUE VEIO PARA OFERECER UM NOVO CONCEITO DE VENDAS E AUXILIAR NO CRESCIMENTO DO <blue>COMÉRCIO LOCAL</blue>.
          </h1>
        </div>

        <div className="section-benefits-ica-card">
          <div className="ica-card-description">
            <h1>CARTÃO SALARIAL COM VÁRIOS <blue>BENEFÍCIOS</blue> PARA VOCÊ</h1>
            <p>O Ica Card é uma administradora de cartões de <b>antecipação salarial com benefícios.</b> O produto nasceu na Bahia com o intuito de administrar os cartões contratados pelos institutos, empresas privadas, sindicatos e associações.</p> <br />
            <p>O objetivo do Ica Card é aumentar o poder aquisitivo do funcionalismo enquanto fortalecemos o comércio local, com total transparência e segurança.</p>
          </div>
          <div className="ica-card-items">
            <h2>NOSSOS BENEFÍCIOS PARA VOCÊ</h2>
            <div className="ica-card-list-items">
              <ul >
                <li>
                  <img src={IconHeart} alt="" />
                  <h3>Seguro de Vida</h3>
                  <p>Em caso de morte acidental a família recebe o valor de R$10.000,00</p>
                </li>
                <li>
                  <img src={IconRose} alt="" />
                  <h3>Auxílio Funeral</h3>
                  <p>Em caso de morte a família recebe para auxílio ao funeral um valor de até R$3.000,00</p>
                </li>
                <li>
                  <img src={IconAcident} alt="" />
                  <h3>Auxílio por Invalidez</h3>
                  <p>Em caso de invalidez acidental, recebera o valor de R$10.000,00</p>
                </li>
                <li>
                  <img src={IconAcident2} alt="" />
                  <h3>Incapacidade Temporária</h3>
                  <p>Receba 60 diárias de R$50,00 cada! Dando um total de R$3.000,00</p>
                </li>
                <li>
                  <img src={IconHospital} alt="" />
                  <h3>Descontos em Farmácias</h3>
                  <p>Desconto de 20 a 75% em medicamentos em farmácias conveniadas com o sistema VIDA LINK</p>
                </li>
                <li>
                  <img src={IconDental} alt="" />
                  <h3>Plano Odontológico</h3>
                  <p>
                  • Abrangência Nacional, <br />
                  • Sem Carência; <br />
                  • Sem Pericia; <br />
                  • Emergência 24h; <br />
                  • Atendimento personalizado;<br />
                  Odonto-móvel: consultório móvel 
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="ica-card-beneficard">
            <img className="ica-card-beneficard-image" src={IcaImage} alt="" />
          <div className="ica-card-beneficard-description">
            <h1>Beneficard</h1>
            <h2>O CARTÃO DE ANTECIPAÇÃO SALARIAL</h2>
            <ul>
              <div>
              <li>Economia de custos bancários (evita entrar no limite do cheque especial)</li>
              <li>Segurança no sistema, não permitindo o excesso do limite</li>
              <li>Sem endividamento</li>
              </div>
              <div>
              <li>Sem cobrança de taxas</li>
              <li>Segurança total nas compras, cartão nominal e intransférivel</li>
              <li>Cartão adicional para até 3 pessoas</li>
              </div>
            </ul>
          </div>
        </div>

        <div className="ica-card-partners">
          <h1>parceiros e clientes</h1>
          <div className="ica-card-partners-list">
          <ul>
            <li><img src={Image1} alt="" /></li>
            <li><img src={Image3} alt="" /></li>
            <li><img src={Image5} alt="" /></li>
            <li><img src={Image7} alt="" /></li>
            <li><img src={Image2} alt="" /></li>
            <li><img src={Image4} alt="" /></li>
            <li><img src={Image6} alt="" /></li>
            <li><img src={Image8} alt="" /></li>
          </ul>
          <img src={Image9} alt="" />
          </div>
        </div>

        <div className="ica-card-partners-mobile">
          <h1>parceiros e clientes</h1>
          <div className="ica-card-partners-list">
          <ul>
            <li><img src={Image1} alt="" /></li>
            <li><img src={Image2} alt="" /></li>
            <li><img src={Image3} alt="" /></li>
            <li><img src={Image4} alt="" /></li>
            <li><img src={Image5} alt="" /></li>
            <li><img src={Image6} alt="" /></li>
            <li><img src={Image7} alt="" /></li>
            <li><img src={Image8} alt="" /></li>
            <li><img src={Image9} alt="" /></li>
          </ul>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default IcaCard;