import React from 'react';
import { Link } from 'react-router-dom';

import './style.css'

export default function ButtonBorder(props) {
  const {text, colorBorder, link, width, height, size, href} = props;
  return(
    <a className="buttonB" style={{
      border: `3px solid ${colorBorder}`,
      width: width,
      height: height,
      fontSize: size
    }} href={href} to={link}>{text}</a>
  );
}