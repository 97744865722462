import React from 'react';

import Image3 from '../../../../images/photos/Image-3.png';

import CardIcon from '../../../../images/icons/Card-icon.svg';
import IconQr from '../../../../images/icons/Icon-qr.svg';
import IconEmail from '../../../../images/icons/Icon-email.svg';
import IconPerson from '../../../../images/icons/icon-person.svg';

import './style.css';

function Complete() {
  return(
    <section className="completao">
        <img className="completed-image" src={Image3} alt=""/>
        <div className="description-complete">
          <h1>CONTA DIGITAL <green>COMPLETONA</green><br/> PRA VOCÊ FAZER O QUE QUISER</h1>
          <div className="descriptions">
            <div className="description-item">
              <img src={CardIcon} alt=""/>
              <h2>Zero anuidade</h2>
              <p>Peça o seu cartão Ica Bank onde e quando quiser sem pagar anuidade. Digital, simples e atrelado a muitos benefícios para você e sua empresa.</p>
            </div>
            <div className="description-item">
              <img src={IconEmail} alt=""/>
              <h2>Transferência sem custo</h2>
              <p>Faça as suas transferências sem custo nenhum com o App da Ica Bank. Uma solução mais que necessária para você não se preocupar com taxas.</p>
            </div>
            <div className="description-item">
              <img src={IconQr} alt=""/>
              <h2>Pagamentos por QR Code</h2>
              <p>Pague suas compras e receba os seus pagamentos de um jeito simples e digital. De onde estiver para quem quiser. O pagamento via QR Code é a solução para você e seu negócio.</p>
            </div>
            <div className="description-item">
              <img src={IconPerson} alt=""/>
              <h2>Suporte</h2>
              <p>Teve algum problema ou imprevisto? Nós temos equipe 24/7 pronta para resolver seu problema seja por chat ou por telefone.</p>
            </div>
          </div>
        </div>
      </section>
  );
}

export default Complete;
