import React from 'react';

import Header from '../../components/Header';
import IntroHome from './sections/IntroHome';
import Benefices from './sections/Benefices';
import Card from './sections/Card';
import Complete from './sections/Complete';
import Maquininha from './sections/Maquininha';
import Pix from './sections/Pix';
import Products from './sections/Products';
import Sac from './sections/Sac';

import Footer from '../../components/Footer';

function Home() {
  return(
    <>
      <Header />
      <IntroHome />
      <Benefices />
      <Card />
      <Maquininha />
      <Products />
      <Sac />
      <Footer />
    </>
  );
}

export default Home;
