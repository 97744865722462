import React from 'react';
import {Link} from 'react-router-dom';

import './style.css';
import Logo from '../../images/icons/Logo.svg'


import { FaBars } from "react-icons/fa";

function Header() {

  function menuToggle({target}) {
    var nav = document.getElementById('menu-mobile')

    if (nav.className === 'active'){
      nav.className = 'off'
    }else {
      nav.className = 'active'
    }

  }

  return(
    <header className="header">
      <div className="logo">
        <Link to="/">
        <img src={Logo} alt=""/>
        </Link>
      </div>
      <div onClick={menuToggle} className="icon-menu">
        <FaBars size={30} />
      </div>
      <div 
        id="menu-mobile" 
        className="off"
      >
        <div onClick={menuToggle} className="icon-menu">
        <FaBars size={30} />
      </div>
        <ul>
          <li>
            <Link to="/empresa">empresarial</Link>
            <div className="line"></div>
          </li>
          <li>
            <Link to="/produtosica">produtos ica</Link>
            <div className="line"></div>
          </li>
        </ul>
      </div>
      <div className="options-header">
        <ul>
          <li><Link to="/empresa">empresarial</Link></li>
          <li><Link to="/produtosica">produtos ica</Link></li>
          <li><a href="/#sac">SAC</a></li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
