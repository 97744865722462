import React from 'react';

import ImagePayCard from '../../../../images/photos/Image-payCard.png';
import IconList from '../../../../images/icons/item-description-benefits.svg'
import './style.css';
import PayCardImage from '../../../../images/photos/Pay-card-image.png'

function Benefices() {
  return(
    <>
    <section className="benefices-sell">
      <img className="image-pay-card" src={ImagePayCard} alt=""/>
      <div className="description-pay-card">
        <h1>BENEFÍCIOS PRA QUEM VENDE</h1>
        <ul>
          <li><img src={IconList} alt="" /><p>Cashback de 1% sobre a folha de pagamento</p></li>
          <li><img src={IconList} alt="" /><p>TED com preço abaixo do mercado</p></li>
          <li><img src={IconList} alt="" /><p>Boleto bancário com menor preço e com benefícios D+1</p></li>
          <li><img src={IconList} alt="" /><p>Maquininha com taxas diferenciadas</p></li>
          <li><img src={IconList} alt="" /><p>Antecipação de recebíveis D+1</p></li>
          <li><img src={IconList} alt="" /><p>Benefícios exclusivos para funcionários que são ICA Bank</p></li>
          <li><img src={IconList} alt="" /><p>Auxílio com rescisão trabalhista*</p></li>
          <li><img src={IconList} alt="" /><p>Plataforma mais completa de e-commerce e wallet</p></li>
        </ul>
        <p className="consult">*Consultar regularmento</p>
      </div>
    </section>
    <section className="benefices-sell-mobile">
      <div className="pay">
        <img src={PayCardImage} alt=""/>
        <h1>BENEFÍCIOS PRA QUEM <green>VENDE</green></h1>
      </div>
      <h5>*Consultar regularmento</h5>
      <ul>
        <li>Cashback de 1% sobre a folha de pagamento</li>
        <li>TED com preço abaixo do mercado</li>
        <li>Boleto bancário com menor preço e com benefícios D+1</li>
        <li>Maquininha com taxas diferenciadas</li>
        <li>Antecipação de recebíveis D+1</li>
        <li>Benefícios exclusivos para funcionários que são ICA Bank</li>
        <li>Auxílio com rescisão trabalhista*</li>
        <li>Plataforma mais completa de e-commerce e wallet</li>
      </ul>
    </section>
    </>
  );
}

export default Benefices;
