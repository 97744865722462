import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ButtonBorder from '../../components/ButtonBorder';

import IcaPay from '../../images/photos/Image-icaPay.png';
import IcaInvest from '../../images/photos/Image-icaInvest.png';
import IcaCred from '../../images/photos/Image-icaCred.png';
import icaCard from '../../images/photos/Image-icaCard.png';

import IconIcaPay from '../../images/icons/Ica-Pay.svg';
import IconIcaInvest from '../../images/icons/Ica-Invest.svg';
import IconIcaCred from '../../images/icons/Ica-Cred.svg';
import IconIcaCard from '../../images/icons/Ica-Card.svg';
import IconIcaCardMobile from '../../images/icons/Icon-ica-card-mobile.svg';

import IcaPayMobile from '../../images/photos/Image-icaPay-mobile.png';
import IcaInvestMobile from '../../images/photos/Image-icaInvest-mobile.png';
import IcaCredMobile from '../../images/photos/Image-icaCred-mobile.png';
import icaCardMobile from '../../images/photos/Image-icaCard-mobile.png';


import './style.css';

function ButtonRedirect(props) {
  const {text, colorBorder, link, width, height, size, href} = props;
  return(
    <a className="buttonB" style={{
      border: `3px solid ${colorBorder}`,
      width: width,
      height: height,
      fontSize: size
    }} href={href} >{text}</a>
  );
}


function ProdutosIca() {
  return(
    <>
      <Header />
      <section className="produtos-ica">

        <h1>PRODUTOS PENSADOS<br/>PARA VOCÊ E SUAS NECESSIDADES</h1>

        <div className="containt-productIca">
          <div className="descpription-productIca">
            <img className="image-icon-products" src={IconIcaPay} alt=""/>
            <p>Uma plataforma para comerciantes e clientes realizarem suas transações de maneira simples e segura. Sem contar com o E-Commerce de centenas de parceiros associados para você poder realizar suas compras e vendas com facilidade e conforto e ainda receber cashback por isso!</p>
            <a className="button-redirect" href="https://www.icapay.com.br/">QUERO VANTAGENS PRA VENDER</a>
          </div>
          <img className="image-person-product" src={IcaPay} alt=""/>
        </div>

        <div className="containt-productIca">
          <img className="image-person-product" src={IcaInvest} alt=""/>
          <div className="descpription-productIca">
            <img className="image-icon-products" src={IconIcaInvest} alt=""/>
            <p style={{marginBottom: '50px'}}>Invista em P2P Lending com o Ica Bank! Diversifique os seus Investimentos e aumente os seus ganhos investindo em Micro e Pequenas Empresas através de compras de recebíveis dos comércios de todo o Brasil. Os investimentos aqui no ICA BANK movimentam as economias municipais de uma forma prática, segura e rentável. Quer investir? Seu ICA INVEST!</p>
            <ButtonRedirect size="14px" width="286px" height="58px" colorBorder="#2C2C2C" text="QUERO COMEÇAR A INVESTIR" href="https://api.whatsapp.com/send?phone=557191072163&text=Ol%C3%A1!%20Gostaria%20de%20uma%20ajuda%20do%20time%20Ica" />
          </div>
        </div>

        <div className="containt-productIca">
          <div className="descpription-productIca">
            <img className="image-icon-products" src={IconIcaCred} alt=""/>
            <p>Aconteceu algum aperto e precisa de dinheiro emprestado? Nós temos a solução ideal para você pessoa física</p>
            <ButtonRedirect href="https://api.whatsapp.com/send?phone=557191072163&text=Ol%C3%A1!%20Gostaria%20de%20uma%20ajuda%20do%20time%20Ica" size="14px" width="286px" height="58px" colorBorder="#540D6E" text="QUERO SAIR DO APERTO" />
          </div>
          <img className="image-person-product" src={IcaCred} alt=""/>
        </div>

        <div className="containt-productIca">
          <img className="image-person-product" src={icaCard} alt=""/>
          <div className="descpription-productIca">
            <img className="image-icon-products" src={IconIcaCard} alt=""/>
            <p>O cartão de crédito que só te dá vantagens em vez de dor de cabeça. Com ele você tem descontos especiais em lojas selecionadas e muito mais.</p>
            <ButtonBorder link="/icacard" size="14px" width="286px" height="58px" colorBorder="#2C2C2C" text="QUERO MEU CARTÃO" />
          </div>
        </div>

      </section>

      <section className="produtos-ica-mobile">
        <h1>PRODUTOS PENSADOS<br/>PARA <green>VOCÊ</green> E SUAS NECESSIDADES</h1>

        <div>
          <img className="Image" src={IcaPayMobile} alt="" />
          <img className="Icon" src={IconIcaPay} alt="" />
          <p>
            Uma plataforma para comerciantes e clientes realizarem suas <blue>transações de maneira simples e segura</blue>. Sem contar com o <blue>E-Commerce</blue> de centenas de parceiros associados para você poder realizar suas compras e vendas com facilidade e conforto e ainda receber cashback por isso!
          </p>
          <ButtonRedirect href="https://www.icapay.com.br/" size="14px" width="286px" height="58px" colorBorder="#009FFD" text="QUERO VANTAGENS PRA VENDER" />
        </div>
        <div>
          <img className="Image" src={IcaInvestMobile} alt="" />
          <img className="Icon" src={IconIcaInvest} alt="" />
          <p>
          <yellow>Invista em P2P Lending com o Ica Bank!</yellow> Diversifique os seus Investimentos e aumente os seus ganhos investindo em Micro e Pequenas Empresas através de compras de recebíveis dos comércios de todo o Brasil. Os investimentos aqui no ICA BANK movimentam as economias municipais de uma forma prática, segura e rentável. Quer investir? Seu ICA INVEST!
          </p>
          <ButtonRedirect href="https://api.whatsapp.com/send?phone=557191072163&text=Ol%C3%A1!%20Gostaria%20de%20uma%20ajuda%20do%20time%20Ica" size="14px" width="286px" height="58px" colorBorder="#F4A500" text="QUERO COMEÇAR A INVESTIR" />
        </div>
        <div>
          <img className="Image" src={IcaCredMobile} alt="" />
          <img className="Icon" src={IconIcaCred} alt="" />
          <p>
          Aconteceu algum aperto e precisa de dinheiro emprestado? Nós temos a solução ideal para você <purple>pessoa física</purple>
          </p>
          <ButtonRedirect href="https://api.whatsapp.com/send?phone=557191072163&text=Ol%C3%A1!%20Gostaria%20de%20uma%20ajuda%20do%20time%20Ica" size="14px" width="286px" height="58px" colorBorder="#540D6E" text="QUERO SAIR DO APERTO" />
        </div>
        <div>
          <img className="Image" src={icaCardMobile} alt="" />
          <img className="Icon" src={IconIcaCardMobile} alt="" />
          <p>
          O <light>cartão de crédito que só te dá vantagens</light> em vez de dor de cabeça. Com ele você tem descontos especiais em lojas selecionadas e muito mais.
          </p>
          <ButtonBorder link="/icacard" size="14px" width="286px" height="58px" colorBorder="#B1D796" text="QUERO MEU CARTÃO" />
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ProdutosIca;
