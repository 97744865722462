import React from 'react';
import ButtonBorder from '../../../../components/ButtonBorder';

import MaquininhaImage from '../../../../images/photos/Maquininha.png';

import MaquininhaImageMobile from '../../../../images/photos/Image-maquininha-mobile.svg';

import './style.css';

function Maquininha() {
  return(
    <>
    <section className="maquininha">
      <img src={MaquininhaImage} alt=""/>
      <div className="maquininha-descpription">
        <h1>BENEFÍCIOS PRA VOCÊ QUE TEM <br/>EMPRESA PRÓPRIA</h1>
        <ul>
          <span>
            <h3>MAQUININHA</h3>
            <li>
              <p>Taxas diferenciadas e com antecipação de recebíveis D+1</p>
            </li>
          </span>
          <span>
            <h3>LOJA VIRTUAL</h3>
            <li>
              <p>Integre a sua Loja Virtual a uma página de pagamentos pronta da Cielo</p>
            </li>
          </span>
          <span>
            <h3>ICA SUPER LINK</h3>
            <li>
              <p>O jeito mais fácil de vender na internet sem precisar de um site. É só gerar o link e vender</p>
            </li>
          </span>
          <span>
            <h3>TECNOLOGIA</h3>
            <li>
              <p>Plataforma com centenas de recursos, fácil integração e adaptável para qualquer dispositivo</p>
            </li>
          </span>
            <ButtonBorder text="QUERO PEDIR UMA MAQUININHA" href="https://wa.me/message/G74XTO3CBWIRA1" colorBorder="#1EF97C" />
        </ul>
      </div>
    </section>
    <section className="maquininha-mobile">
      <img src={MaquininhaImageMobile} alt="" />
      <h1>BENEFÍCIOS PRA VOCÊ QUE TEM EMPRESA PRÓPRIA</h1>
      <ul>
        <div>
          <h2>MAQUININHA</h2>
          <li>
            <p>
            Taxas diferenciadas e com antecipação de recebíveis D+1
            </p>
          </li>
        </div>
        <div>
          <h2>LOJA VIRTUAL</h2>
          <li>
            <p>
            Integre a sua Loja Virtual a uma página de pagamentos pronta da Cielo
            </p>
          </li>
        </div>
        <div>
          <h2>ICA SUPER LINK</h2>
          <li>
            <p>
            O jeito mais fácil de vender na internet sem precisar de um site. É só gerar o link e vender
            </p>
          </li>
        </div>
        <div>
          <h2>TECNOLOGIA</h2>
          <li>
            <p>
            Plataforma com centenas de recursos, fácil integração e adaptável para qualquer dispositivo
            </p>
          </li>
        </div>
      </ul>
    </section>
    </>
  );
}

export default Maquininha;
