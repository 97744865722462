import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import IntroHome from './sections/IntroHome';
import Maquininha from './sections/Maquininha';
import Benefices from './sections/BeneficesSell';
import BeneficiesFuncionaries from './sections/BeneficesFuncionaries';
import Ecommerce from './sections/Ecommerce';

function IcaEmpresa() {
  return(
    <>
      <Header />
      <IntroHome />
      <Maquininha />
      <Benefices />
      <BeneficiesFuncionaries />
      <Ecommerce />
      <Footer />
    </>
  );
}

export default IcaEmpresa;
