import React from 'react';
import { useHistory } from 'react-router-dom';

import ButtonBorder from '../../../../components/ButtonBorder';

import ImagePixelPerson from '../../../../images/photos/Image-pix.png';
import ImagePix from '../../../../images/photos/Image-pix2.png';
import ImagePixMobile from '../../../../images/photos/Image-pix-mobile.png';
import LogoPix from '../../../../images/photos/logo-pix.svg'

import './style.css';

function Pix() {
  const history = useHistory()
  return(
    <>
    <section className="pix">
      <div className="pix-content">
        <div className="pix-texts">
          <img src={ImagePix} alt=""/>
          <div className="pix-item">
            <h1>Pix, transferências e saques gratuitos</h1>
            <p>Envie e receba dinheiro de forma mais prática e rápida. Faça Pix, TEDs e transferências  para todos os bancos sem pagar nada.</p>
          </div>
          <div className="pix-item2">
            <h1>Como o Pix funciona?</h1>
            <p>Pix funciona como ferramenta que facilita transferências, pagamentos e recebimentos de dinheiro na sua conta. Você pode cadastrar uma ou mais chaves no Inter. Os formatos de chaves disponíveis são: número de telefone, CPF ou CNPJ, e-mail ou uma chave aleatória criada pelo Super App. Chaves Pix são códigos que vão representar sua conta bancária. Ou seja, em vez de passar o número da sua agência e conta, você precisará compartilhar apenas uma de suas chaves cadastradas.</p>
          </div>
        </div>
        <img className="pix-person" src={ImagePixelPerson} alt=""/>
      </div>
      <ButtonBorder text="CONHEÇA O NOSSO ANTIFRAUDE PIX" link="/protecaopix" colorBorder="#1EF97C" />
    </section>

    <section className="pix-mobile">
      <div>
        <h1>Pix, transferências e saques gratuitos</h1>
        <img className="image-pix-mobile" src={ImagePixMobile} alt="" />
        <h3>Envie e receba dinheiro de forma mais prática e rápida. Faça Pix, TEDs e transferências  para todos os bancos sem pagar nada.</h3>
        <div onClick={() => {
          history.push('/protecaopix')
        }} className="button-pix-mobile">
          CONHEÇA O NOSSO ANTIFRAUDE PIX
        </div>
        <img className="logo-pix" src={LogoPix} alt="" />
      </div>
    </section>
    </>
  );
}

export default Pix;
