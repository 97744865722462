import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import Image from '../../images/photos/ica-indique.png'
import Image2 from '../../images/photos/ica-indique2.png'

import './style.css';

function IndiqueGanhe() {
  return (
    <>
    
      <h1>Pagina nao encontrada!</h1>
    </>
  );
}

export default IndiqueGanhe;