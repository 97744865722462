import React from 'react';
import PDFViewer from '../../components/PDFViewer';
import invest from '../../utils/invest.pdf'

import './style.css'

function Politic() {
  const path = 'https://icabank-site.s3.amazonaws.com/2024-VERSAO_5.0_POLITICA_DE_PRIVACIDADE_EXTERNA.pdf'

  return(
    <div className='intro-politic' style={{margin: 0, padding: 0}} >
      <h1>Política de Privacidade</h1>
      
      <PDFViewer pdfPath={'https://icabank-site.s3.amazonaws.com/2024-VERSAO_5.0_POLITICA_DE_PRIVACIDADE_EXTERNA.pdf'} />
    </div>
  );
}

export default Politic;
