import React from 'react';
import Footer from '../../components/Footer';

import Header from '../../components/Header';

import ImageError from '../../images/photos/Image-error.png';

import './style.css';

function Error404() {
  return(
    <>
      <Header />
      <section className="error-404">
        <img src={ImageError} alt=""/>
        <div>
          <h1><green>404</green></h1>
          <h2>Página não encontrada :(</h2>
          <p>Tem algo de errado com seu link</p>
        </div>
      </section>
      <section className="error-404-mobile">
        <div>
          <h1><green>404</green></h1>
          <h2>Página não encontrada :(</h2>
          <p>Tem algo de errado com seu link</p>
        </div>
        <img src={ImageError} alt=""/>
      </section>
      <Footer />
    </>
  );
}

export default Error404;
